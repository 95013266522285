.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  background-color: lightblue;
}
.container{
 justify-content: center;
  display: flex;
  padding: 50px;
}
.card{
  background: white;
  width:70%;
}
.ant-card-head-title{
  font-size: 40px;
  padding: 10px;
}
.ant-tabs-tab-btn{
  font-size:15px;
  padding: 10px;
}
.tab-item {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.input{
  margin-bottom:10px
}
.card-item{
  height:600px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
